<template>
  <div id="carousel-home" class="carousel slide carousel-fade" :data-interval="(getBanners.interval) ? 1000*getBanners.interval : 2000" data-ride="carousel">
      <ol class="carousel-indicators d-none">
        <li class="nav-item" v-for="( item, key ) in getBanners.items" :key="key" data-target="#carousel-home" :data-slide-to="key" :class="{ active: (key==0) }"></li>
      </ol>

      <div class="carousel-inner">
        <div  v-for="( { id, image, link, image_mobile }, key ) in getBanners.items" :key="key" :class="{ active: (key==0) }" class="carousel-item" v-touch:swipe="swipeHandler">
          <router-link :to="link">
            <img class="d-block d-md-none w-100" :src="image_mobile" alt="">
            <img class="d-md-block d-none w-100" :src="image" alt="">
          </router-link>
        </div>
      </div>
      <a class="carousel-control-prev" href="#carousel-home" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#carousel-home" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
  </div>
</template>
<script>
  export default {
    props: {
      app_url: {
        type: String,
        default: ''
      },
    },
    name: 'Banners',
    computed: {
      getBanners(){ //final output from here
        return this.$store.getters.getBanners
      }
    },
    methods: {
      swipeHandler (direction) {

        if ( direction === 'right' )
          $('#carousel-home').carousel('next')

        if ( direction === 'left' )
          $('#carousel-home').carousel('prev')

        console.log(direction,  direction === 'left', direction === 'right' )  // May be left / right / top / bottom
      }
    },
    mounted(){
      $('#carousel-home').carousel()
    },
    update(){
      $('#carousel-home').carousel()
    }
  }
</script>
