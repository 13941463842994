<template>
  <main role="main">
      <section id="garantia" class="bg-garantia main-padding-top">
      	<div class="container py-5">
          	<div class="row">
              	<div class="col-12 mb-5"><img :src="app_url+'/images/titulo-garantia.png'" class="img-fluid"/></div>
                  <div class="col-lg-6">
                  	<p class="font-montserrat">Cuando se adquiere un vehículo, aun sabiendo que éste es el mejor, el consumidor desea que el mismo esté amparado de los posibles daños que se presenten en los días siguientes a su compra. Es así como todo producto que representa una significativa inversión, posee un respaldo de garantía tal como el que le ofrece Gilera Motors Argentina a sus unidades.</p>
                      <p class="font-montserrat">Para hacer efectivo este beneficio, todos nuestros productos poseen un manual de garantía, el cual debe ser llenado en forma completa. Asimismo, el “Talón de garantía y constancia de entrega”, tal como dice al dorso del mismo, debe enviarse a la división Garantías de Gilera dentro de los primeros 15 días de entregado el vehículo a su propietario.Después de tramitar correctamente el Manual de garantía, el propietario está en condiciones de efectuar sus reclamos en cualquier lugar del territorio nacional en los concesionarios o talleres autorizados de nuestra amplia red de servicios.</p>
                      <p class="font-montserrat">En el Manual de Garantía se describen las condiciones de la misma, los plazos, las partes que cubre, los requisitos y sus limitaciones.</p>
                      <p class="font-montserrat">Si cualquier pieza original presenta defectos o fallas de material, ya sea por el método de fabricación o de ensamble empleado, durante los primeros 12 meses o 12000 Km (lo que ocurra primero), Gilera Motors Argentina se compromete a efectuar el cambio de la pieza defectuosa en cualquiera de nuestros centros habilitados en toda la extensión del país.</p>
                      <a :href="app_url+'storage/GARANTIA-GILERA.pdf'" target="_blank" class="btn btn-link font-weight-semibold p-0 btn-lg">DESCARGAR</a>
                  </div>
              </div>
          </div>
      </section>

      <section id="repuestos" class="bg-repuesto">
      	<div class="container py-md-5 h-100 d-flex align-items-center">
          	<div class="row pb-md-5 mb-md-5">
              	<div class="col-12 mb-5 text-md-right"><img :src="app_url+'/images/titulo-repuestos.png'" class="img-fluid"/></div>
                  <div class="col-lg-7 offset-lg-5 mb-md-5 pb-md-5">
                  	<p class="font-montserrat">La división Repuestos cuenta con un amplio y variado stock a fines de abastecer como corresponde, tanto a particulares como a la red de concesionarios de la marca, con precios competitivos y velocidad en los despachos.</p>
                      <p class="font-montserrat">El centro de repuestos oficial es: Av. Rivadavia 10.766, Buenos Aires. 011 4641 2444.</p>

                      <a  v-if="false" href="https://tienda.mercadolibre.com.ar/gilera" target="_blank" class="btn btn-link font-weight-semibold p-0 btn-lg align-top mr-5">TIENDA OFICIAL</a>
                      <a  v-if="false" href="https://tienda.mercadolibre.com.ar/gilera" target="_blank"><img :src="app_url+'/images/logo_meli.png'" class="img-fluid"/></a>
                  </div>
          	</div>
          </div>
      </section>

      <section id="contacto" class="bg-contactanos">
      	<div class="container pt-5">
          	<div class="row py-5"><div class="col-12"><img :src="app_url+'/images/titulo-contactanos.png'" class="img-fluid"/></div></div>
          	<div class="row">
              	<div class="col-md-8 offset-md-2 pb-5">
                      <div class="form-contacto">
                      	<h1 class="text-center pt-4 mb-5 font-weight-light text-uppercase">NOS INTERESA TU OPINIÓN</h1>
                          <div>
                            <form-contact-component :app_url="app_url"></form-contact-component>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>

  </main>
</template>
<script>
export default {
  props: {
    app_url: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      loading: null,
    };
  },
  created() {
    this.loading=false;
  },
  updated(){
    //setTimeout(this.$scrollTo("#"+this.$route.name), 1800);
  },
  mounted(){
    //setTimeout(this.$scrollTo("#"+this.$route.name), 1800);
    var self = this;
    setTimeout(function(){
      self.loading = true;
    }, 1800);

  },
}
</script>
