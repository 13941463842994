<template>
  <main role="main">

    <section class="map-concesionarias main-padding-top">
    	<div class="container py-5">
        	<div class="row">
            	<div class="col-md-3">
                	<div class="concesionarias-menu">
                    	<div class="select-menu-content mb-4">
                    	<select  ref='select_zone' id="select-zone" class="select-menu form-control">
                        <optgroup v-for="(province, key) in provinces" :key="province.id" :value="province.id" :label="province.name" v-if="Boolean(province.total_concessionaries)">
                      	  <option data-type="zone" class="zone" :class="'parent-'+key" v-for="zone in province.zones" :key="zone.id" :value="zone.id" v-if="Boolean(zone.total_concessionaries)">{{ zone.name }}</option>
                          <option data-type="province" class="province" :value="province.id">{{ province.name }}</option>
                        </optgroup>
                        </select>
                        </div>
                        <div class="concesionarias-resultados">
                        	<div v-for="(concessionary, key) in filteredConcessionaires" :key="concessionary.id" class="item" @mouseover="concessionaryHover(true,key)" @mouseleave="concessionaryHover(false, key) " @click="selectConcessionary(concessionary.id)">
                            	<h3 class="text-primary"><b>{{concessionary.name}}</b></h3>
                                <h5 class="font-weight-light">{{concessionary.address}}<br>{{ concessionary.province ? concessionary.province.name : ''}}</h5>
                                <h6>{{ concessionary.telephone }}<br><a v-if="false" href="mailto:info@gilereamotors.com.ar">info@gilereamotors.com.ar</a></h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-9">
                	<div class="concesionarias-map">
                        <div class="embed-responsive embed-responsive-full">
                          <div class="google-map embed-responsive-item" ref="googleMap"></div>
                             <template v-if="Boolean(this.google) && Boolean(this.map)">
                               <slot
                                 :google="google"
                                 :map="map"
                               />
                             </template>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</main>
</template>
<script>
import GoogleMapsApiLoader from 'google-maps-api-loader'
import apiZones from '../api/zones';
import apiProvinces from '../api/provinces';
import apiConcessionaires from '../api/concessionaires';

export default {
  props: {
    app_url: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      google: null,
      map: null,
      provinces: [],
      zone: null,
      province: null,
      concesionaries: [],
      markers: [],
      current_item: null,
      location:null,
      gettingLocation: false,
      errorLocation:false,
      first_load: true,
      concessionary: "",
    }
  },
  created() {
    this.locateMe();
  },

  async mounted() {

    const googleMapApi = await GoogleMapsApiLoader({
      apiKey: process.env.MIX_GOOGLE_MAPS_KEY
    })

    this.google = googleMapApi

    this.initializeMap()

    $('#select-zone').select2({
      theme: 'bootstrap4',
      dropdownParent: $('.select-menu-content'),
      dropdownCssClass: 'form-control-menu-dropdown',
      selectionCssClass: 'form-control-menu',
      minimumResultsForSearch: -1,
      closeOnSelect: false
    })
    .on('select2:select', (e) => {
      var data = e.params.data;
      //console.log('select2:select',e)

      if ( $(data.element).hasClass("province") )
      {
        this.zone = null;
        this.province = data.id;
      }
      else{
        this.zone = data.id;
        this.province = null;
        $('#select-zone').select2('close');
      }
      this.$store.commit('setConcessionary', null)
      this.$root.$emit('updateSelectZone');
    });

    $("body").on("click", ".select2-results__option", (e)=>{
      $(e.currentTarget).find("ul:first").toggleClass('active');
      $(e.currentTarget).find("ul > li:last-child").trigger("mouseup")
    })

    this.getProvinces();

    this.$root.$on('updateConcessionary', () => {
      if (this.current_item != this.concessionary_id ){
        this.current_item = this.concessionary_id
        /*
        if (this.currentConcessionary.zone){
          this.zone = this.currentConcessionary.zone.id
        }
        */
        this.renderMarkers()
      }
    });

  },
  methods: {
    initializeMap() {
      const mapContainer = this.$refs.googleMap
      this.map = new this.google.maps.Map( mapContainer, {
        center: {
          lat: -34.58266076389075,
          lng: -58.45093328501139
        },        
        zoom: 11,
        minZoom: 4,
        maxZoom: 17,
      })
    },
    concessionaryHover(hover, id){
      if (hover){
        this.google.maps.event.trigger(this.markers[id], 'mouseover');
      }
      else {
        this.google.maps.event.trigger(this.markers[id], 'mouseout');
      }
    },
    getProvinces(){
      apiProvinces.all().then((response) => {
        this.provinces = response.data.data;
        if (this.currentConcessionary && this.currentConcessionary.zone){
          this.zone = this.currentConcessionary.zone.id;
        }
        else if(!this.location) {
          //this.zone = response.data.data[0].zones[0].id;
        }
      }).catch( error => {
        console.log(error)
      });
    },
    addMarkerMap(data, posision){

      var marker = new this.google.maps.Marker({
        position: posision,
        map: this.map,
        title: data.name,
        icon: this.app_url + '/images/marker.png'
      });

      marker.addListener('click', () => {
        $('a[data-id="'+ data.id +'"').trigger("click");
      });

      var contentString = '<div id="content">' +
        '<h3 class="text-primary"><b>' + data.name + '</b></h3>'+
        '<h5 class="font-weight-light">' + data.address + '<br>' + (data.province ? data.province.name : '') + '</h5>'+
        '<h6>' + (data.telephone ? data.telephone : '')  +'<br></h6>'+
        "</div>";

        var infowindow = new this.google.maps.InfoWindow({
            content: contentString            
          });

      marker.addListener('mouseover', () => {
        // infowindow.open(this.map, marker);
        infowindow.open({anchor: marker, shouldFocus: false});        
      });

      // assuming you also want to hide the infowindow when user mouses-out
      marker.addListener('mouseout', () => {
        infowindow.close();
      });

      this.markers.push(marker);

    },
    renderMarkers(){

      for (let i=0; i< this.markers.length; i++) {
        this.markers[i].setMap(null);
      }
      this.markers = [];

      let items = this.filteredConcessionaires;
      let limits = new this.google.maps.LatLngBounds();

      $.each(items, (key, item) => {
        const posision = new this.google.maps.LatLng(item.coords.lat,item.coords.lng);
        this.addMarkerMap(item, posision);
        if (this.current_item){
          if ( item.id == this.current_item )
            limits.extend(posision);
        }
        else{
          limits.extend(posision);
        }
      });

      if ( !items.length ) {
        this.map.setZoom(4)
      }
      else {
        this.map.fitBounds(limits);
        if ( items.length == 1 ) {
          this.map.setZoom(15)
        }
      }
    },
    selectConcessionary(id){

      this.$store.commit('setConcessionary', id)
      this.$root.$emit('updateConcessionary');
    },
    async getLocation() {
      return new Promise((resolve, reject) => {
        if(!("geolocation" in navigator)) {
          reject(new Error('Geolocation is not available.'));
        }
        navigator.geolocation.getCurrentPosition(pos => {
          resolve(pos);
        }, err => {
          reject(err);
        });
      });
    },

    async locateMe() {
      this.gettingLocation = true;
      try {
        this.gettingLocation = false;
        this.location = await this.getLocation();

        this.errorLocation = false;

        apiConcessionaires.all({
          lat: this.location.coords.latitude,
          lng: this.location.coords.longitude
        }).then(response => {
          this.concesionaries = response.data.data;
          this.zone = null;
          $(this.$refs.select_zone).val("");
          $('#select-zone').trigger('change.select2');
          this.renderMarkers();
          this.$root.$emit('updateSelectZone');
        })

      }
      catch(e) {
        this.gettingLocation = false;
        this.errorLocation = true;
      }
    }
  },
  computed: {
    filteredConcessionaires() {
      let concessionaires = this.$store.getters.getConcessionairesFormGetters;

      if (this.concesionaries.length)
        concessionaires = this.concesionaries

      if (this.zone){
        return concessionaires.filter(item => {
          return  item.zone && item.zone.id == this.zone
        });
      }
      else if (this.province){
        return concessionaires.filter(item => {
          return  item.province && item.province.id == this.province
        });
      }
      else {
        return concessionaires
      }
    },
    currentConcessionary() {
      return this.$store.getters.getConcessionairesFormGetters.find(item =>{
        return item.id == this.concessionary_id
      });
    },
    concessionary_id() {
      return this.$store.getters.getConcessionary;
    }
  },
  updated(){

    if (this.current_item != this.concessionary_id){
      this.current_item = this.concessionary_id
    }
    if (this.map){
      this.renderMarkers()
    }
    $('#select-zone').trigger('change.select2');
  },
}
</script>
