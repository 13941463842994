import apiCategories from '../api/categories';
import apiConcessionaires from '../api/concessionaires';
import apiInstagram from '../api/instagram';
import apiBanners from '../api/banners';

export default {
	state: {
		categories: [],
		concessionaires:[],
		concessionary: '',
		instagram_feeds:[],
		banners:[],
	},

	getters: {
		getCategoriesFormGetters(state){//take parameter state
			return state.categories
   	},
		getConcessionairesFormGetters(state){
			return state.concessionaires
   	},
		getConcessionary: state => {
     return state.concessionary
	 	},
		getInstagramFeeds: state => {
			return state.instagram_feeds
		},
		getBanners: state => {
			return state.banners
		},
	},

	actions: {
		allCategoryFromDatabase(context){
			apiCategories.all()
			.then( response => {
        //console.log('categories ->', response.data)
        context.commit("categories",response.data.data) //categories will be run from mutation
      })
      .catch( error => {
        console.log("Error -> ",error)
      })
    },
		allConcessionaryFromDatabase(context){
			apiConcessionaires.all()
			.then( response => {
        //console.log('concessionaires ->', response.data)
        context.commit("concessionaires",response.data.data)
      })
      .catch( error => {
        console.log("Error -> ",error)
      })
		},
		allInstagramFeedsFromDatabase(context){
			apiInstagram.all()
			.then( response => {
				//console.log('allInstagramFeedsFromDatabase ->', response.data.data)
				context.commit("setInstagramFeeds",response.data.data)
      })
      .catch( error => {
        console.log("Error -> ",error)
      })
		},
		allBannersFromDatabase(context){
			apiBanners.all()
			.then( response => {
				//console.log('allInstagramFeedsFromDatabase ->', response.data.data)
				context.commit("banners",response.data.data)
			})
			.catch( error => {
				console.log("Error -> ",error)
			})
		}

	},

	mutations: {
    categories(state,data) {
      return state.categories = data
    },
		concessionaires(state,data) {
			return state.concessionaires = data
		},
		setConcessionary (state, payload) {
     state.concessionary = payload
	 	},
		setInstagramFeeds (state, payload) {
     state.instagram_feeds = payload
	 	},
		banners (state, data) {
     state.banners = data
	 	},
	}
}
