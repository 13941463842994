<template>
  <div>
    <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in" :duration="200">
    <div v-if="message" class="alert">
      <p>{{message}}</p>
    </div>
    </transition>
    <transition name="fade">
    <div v-if="errors.length" class="alert">
      <b>Por favor, corrija el(los) siguiente(s) error(es):</b>
        <ul>
          <li v-for="error in errors">{{ error }}</li>
        </ul>
    </div>
    </transition>
    <form  @submit.prevent="onSubmit($event)">
      <div class="row form-group mb-md-4">
        <div class="col-md-6 mb-md-0 mb-4">
          <input type="text" class="form-control" placeholder="Nombre*" id="contact_name" v-model="contact.name">
        </div>
        <div class="col-md-6 mb-md-0 mb-4">
          <input type="text" class="form-control" placeholder="Mail*" id="contact_email" v-model="contact.email">
        </div>
      </div>
      <div class="row form-group mb-md-4">
        <div class="col-md-6 mb-md-0 mb-4">
          <input type="text" class="form-control" placeholder="Teléfono*" id="contact_telephone" v-model="contact.telephone">
        </div>
        <div class="col mb-md-0 mb-4">
          <div class="box-dropdown">
              <select class="custom-select" data-placeholder="Sector de contacto"  id="contact_area" v-model="contact.area">
                <!-- este primero debe quedar vacio -->
                <option disabled value=""></option>
                <option value="Quiero comprar una moto">- Quiero comprar una moto</option>
                <option value="Repuestos">- Repuestos</option>
                <option value="Garantía">- Garantía</option>
                <option value="Quiero ser concesionario">- Quiero ser concesionario</option>
              </select>
          </div>
        </div>
      </div>
      <div class="row form-group mb-md-4">
        <div class="col-12">
          <label class="text-primary">Mensaje</label>
            <textarea class="form-control" rows="5" id="contact_message" v-model="contact.message"></textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-right">
          <button type="submit" class="btn btn-link btn-enviar p-0" :disabled="saving">
            <span class="text-uppercase">  {{ saving ? 'Enviando...' : 'Enviar' }}</span> <i><img :src="app_url+'/images/ico-enviar.png'"/></i>
          </button>
        </div>
      </div>

    </form>
  </div>
</template>

<script>
  import api from '../api/contact';

  export default {
    props: {
      app_url: {
        type: String,
        default: ''
      },
    },
    data() {
      return {
        errors: [],
        saving: false,
        message: false,
        contact: {
          name:'',
          email:'',
          telephone:'',
          area:'',
          message:'',
        }
      }
    },
    methods: {
      onSubmit($event) {
        if (this.checkForm()){

          let self = this;
          this.saving = true
          this.message = false
          api.send(this.contact)
          .then((data) => {
            this.message = "Mensaje Enviado.";
            this.contact = {
              name:'',
              email:'',
              telephone:'',
              area:'',
              message:'',
            }
            setTimeout(()=>{
              this.message = false;
            }, 3500);
          })
          .catch((e) => {
            this.message = e.response.data.message || 'Hubo un problema al enviar el mensaje.';
            setTimeout(()=>{
              this.message = false;
            }, 3500);
          })
          .then(() => this.saving = false)
        }
      },
      checkForm: function () {
        this.errors = [];
        var self = this;
        if (!this.contact.name) {
          this.errors.push("El nombre es obligatorio.");
        }
        if (!this.contact.telephone) {
          this.errors.push("El teléfono es obligatorio.");
        }

        if (!this.contact.email) {
          this.errors.push('El email es obligatorio.');
        }
        else if (!this.validEmail(this.contact.email)) {
          this.errors.push('El email debe ser válido.');
        }

        /*
        if (this.email && !this.validEmail(this.email)) {
          this.errors.push('El correo electrónico debe ser válido.');
        }
        */
        setTimeout(()=>{
          this.errors = false;
        }, 5500);
        if (this.errors.length) {
          return false;
        }
        return  true;
      },
      validEmail: function (email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
      }

    },
    mounted() {
      let self = this;

       $('#contact_area').select2({
         theme: 'bootstrap4',
         dropdownParent: $('.box-dropdown'),
         dropdownCssClass: 'form-control-dropdown',
         selectionCssClass: 'form-control',
         minimumResultsForSearch: -1,
       })
       .on('select2:select', function (e) {
        var data = e.params.data;
        self.contact.area = data.id;
      });

    },
    updated(){
      $('#contact_area').trigger('change.select2');
    },
  }
</script>
