<template>

  <div class="modelo-content" v-if="loaded" :id="current_slug">
    <div class="info-modelo border-top">
      <div class="container">
        <div id="carousel-modelo" class="carousel slide carousel-fade" :data-interval="(model.interval) ? 1000*model.interval : 2000" data-ride="carousel">

          <ol class="carousel-indicators">
            <li v-for="(item, index) in model.cover_images" :key="index" data-target="#carousel-modelo" :data-slide-to="index" :class="{ active: (index==0) }" :style="{borderColor: item.color}">
              <span :style="{backgroundColor: item.color}"></span>
            </li>
          </ol>
          <div class="carousel-inner">
            <div v-for="(item, index) in model.cover_images" :key="index" :class="{ active: (index==0) }" class="carousel-item" v-touch:swipe="swipeHandler">
              <img class="d-block w-100 img-fluid" :src="item.src_image" :alt="model.name" width="1240" height="704">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="data-modelo border-top">
        <div class="container">
            <div class="d-flex align-items-center justify-content-center py-4 data-icons-tab">
              <div v-for="(item, index) in model.characteristics" :key="index" class="d-inline-flex align-items-center data-icons-item">
                <img :src="item.icon" :alt="item.label" width="62" height="56" class="mr-lg-4 mr-2"/>
                <h6 class="mb-0 pr-lg-4">{{ item.label }} <br class="d-none d-md-inline">{{ item.value }}</h6>
              </div>
            </div>
            <div class="row py-4">
                <div class="col-md-6">

                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col" colspan="2">DIMENSIONES Y PESO</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in model.dimensiones_y_peso" >
                        <th scope="row">{{item.label}}</th>
                        <td>{{item.value}}</td>
                      </tr>
                    </tbody>
                  </table>


                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col" colspan="2">Motor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in model.motor" >
                          <th scope="row">{{item.label}}</th>
                          <td>{{item.value}}</td>
                        </tr>
                      </tbody>
                    </table>
                </div>
                <div class="col-md-6">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col" colspan="2">CHASIS</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in model.chasis" >
                          <th scope="row">{{item.label}}</th>
                          <td>{{item.value}}</td>
                        </tr>
                      </tbody>
                    </table>
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col" colspan="2">RUEDAS Y FRENOS</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in model.ruedas_y_frenos" >
                          <th scope="row">{{item.label}}</th>
                          <td>{{item.value}}</td>
                        </tr>
                      </tbody>
                    </table>
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col" colspan="2">OTROS</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in model.otros" >
                          <th scope="row">{{item.label}}</th>
                          <td>{{item.value}}</td>
                        </tr>
                      </tbody>
                    </table>
                </div>
            </div>
        </div>
     </div>
     <div class="links-modelo border-top border-bottom pt-3 pb-2">
        <div class="container">
            <div class="d-flex">
                <ul class="nav mr-auto">
                  <li v-if="model.manual" class="nav-item"><a class="nav-link" target="_blank" :href="model.manual.src_url">manual</a></li>
                  <li class="nav-item"><router-link :to="{ name: 'garantia' }" class="nav-link">Garantia</router-link></li>
                  <li class="nav-item" :class="{ invisible: ( model.parts_link === '' || model.parts_link === null ) }" ><a :href="model.parts_link" target="_blank" class="nav-link">Repuestos</a></li>
                </ul>
                <ul class="nav">
                  <li class="nav-item"><a class="nav-link" :href="'whatsapp://send?text='+ encodeURI( host + app_url) +'/modelo/'+ model.slug +' ' +model.name">compartila</a></li>
                </ul>
            </div>
        </div>
     </div>
     <div class="galeria-modelo">
     	<div class="container">
            <div class="slick">
              <div v-for="(item, index) in model.images" :key="index">
                <img :src="item" :alt="model.name" width="820" height="545" class="img-fluid mx-auto">
              </div>
            </div>
        </div>
     </div>
 </div><!-- end modelo content -->
</template>
<script>
  import api from '../api/models';
  export default {
    props: {
      app_url: {
        type: String,
        default: ''
      },
      model_slug: {
        type: String,
        default: ''
      }
    },
    name: 'modelItem',

    data() {
      return {
        loaded: false,
        model: null,
        current_slug: null,
        host: location.protocol.concat("//").concat(window.location.hostname)
      };
    },
    created() {
      this.loadModel()
    },
    updated(){
       this.loadModel()
       if (this.loaded)
        this.initilize()
    },
    mounted(){
      this.initilize()
    },

    methods : {
     loadModel(){
       if ( this.model_slug != this.current_slug ){
         this.$Progress.start();
         this.current_slug = this.model_slug;
         this.loaded = false;
         this.$emit('load-model', false);
         api.find(this.model_slug).then( response => {
           this.loaded = true;
           this.model = response.data.data;
           this.$emit('load-model', true, this.model.category.slug);
           this.$Progress.finish();
         }).catch( error => {
           this.$emit('load-model', false);
           this.$Progress.finish();
         });
       }
     },
     swipeHandler (direction) {

         if ( direction === 'right' )
           $('#carousel-modelo').carousel('next')

         if ( direction === 'left' )
           $('#carousel-modelo').carousel('prev')

         console.log(direction,  direction === 'left', direction === 'right' )  // May be left / right / top / bottom
     },
     initilize(){
       /*
       $('select.custom-select').select2({
         theme: 'bootstrap4',
         dropdownParent: $('.box-dropdown'),
         dropdownCssClass: 'form-control-dropdown',
         selectionCssClass: 'form-control',
         minimumResultsForSearch: -1,
       });*/
      $('#carousel-modelo').carousel()

      $('.slick').slick({
         infinite: true,
         slidesToShow: 3,
         slidesToScroll: 1,
         centerMode: true,
         responsive: [{
           breakpoint: 767,
           settings: {
             slidesToShow: 1,
             centerMode: false
	        }
	      }]
   	  });
     }
   }

  }
</script>
