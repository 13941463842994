<template>
  <header>
  	<div class="collapse" id="navbarHeader">
          <div class="content">
              <div class="container">
              	<div class="col-sm-12 text-center mb-5">
              		<button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarHeader" aria-controls="navbarHeader" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
                  </div>
                  <div class="row">
                      <div class="col-sm-12 text-center mb-3">
                          <ul class="list-unstyled">
                            <li><router-link data-toggle="collapse" data-target="#navbarHeader" :to="{ name: 'modelos' }" >Modelos</router-link></li>
	                          <li><router-link data-toggle="collapse" data-target="#navbarHeader" :to="{ name: 'nosotros' }" >Nosotros</router-link></li>
			                      <li><router-link data-toggle="collapse" data-target="#navbarHeader" :to="{ name: 'concesionarios' }" >Concesionarios</router-link></li>
	                          <li><router-link data-toggle="collapse" data-target="#navbarHeader" :to="{ name: 'garantia' }">Garantía</router-link></li>
                            <li><router-link data-toggle="collapse" data-target="#navbarHeader" :to="{ name: 'repuestos' }">Repuestos</router-link></li>
                            <li><router-link data-toggle="collapse" data-target="#navbarHeader" :to="{ name: 'contacto' }">Contacto</router-link></li>
                          </ul>
                      </div>
                      <div class="col-sm-12 text-center">
                          <div class="social-medias">
                              <a href="https://www.facebook.com/GileraMotorsARG" class="icons mx-1"><img :src="app_url+'/images/icon-facebook.svg'" width="30" height="30" alt="facebook"/></a>
                              <a href="https://www.instagram.com/gileramotorsarg" class="icons mx-1"><img :src="app_url+'/images/icon-instagram.svg'" width="30" height="30" alt="instagram"/></a>
                          </div>
                      </div>
               </div>
            </div>
        </div>
     </div>
     <div class="navbar navbar-light">
       <div class="container-fluid d-flex justify-content-between align-items-start">
         <router-link :to="{ name: 'home' }" class="navbar-brand d-flex align-items-center">
           <img :src="app_url+'/images/gilera.png'" width="100" height="100" class="d-inline-block align-top img-fluid" alt="Gilera"/>
         </router-link>

         <div class="d-flex flex-md-row-reverse justify-content-between nav-right">
             <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarHeader" aria-controls="navbarHeader" aria-expanded="false" aria-label="Toggle navigation">
               <span class="navbar-toggler-icon"></span>
             </button>
             <form class="form-inline my-md-2 my-lg-0 form-dropdown" id="search-sucursales">
             	<select data-placeholder="Concesionarios" v-model="concessionary">
              	<option value=""></option>
                <option  v-for="item in getConcessionaires" :key="item.id" :value="item.id">
                  {{ item.name + ((item.zone) ? ', ' + item.zone.name : '' ) + ((item.province) ? ', ' + item.province.name : '' )   }}
                </option>
             	</select>
              <!--<button type="submit" class="btn btn-ico btn-link rounded-0"><img :src="app_url+'/images/lupa.png'" width="16" height="16"/></button>-->
             </form>
         </div>
       </div>
     </div>
  </header>
</template>
<script>
  export default {
    data(){
      return {
        concessionary : "",
        app_url: "",
      }
    },
    created() {
      this.app_url = this.$router.options.base;
    },
    mounted(){
      var self = this;
      $('#search-sucursales select').select2({
        theme: 'bootstrap4',
        dropdownParent: $('.form-dropdown'),
      }).on('select2:select', function (e) {
        var data = e.params.data;
        self.concessionary = data.id;
        self.onSelectConcessionary();
     })
     .on("select2:closing", function(e){
       $(".select2-results .view-more").remove();
     })
     .on('select2:open', function() {
       $(".select2-results").append('<a href="#" class="btn-block btn btn-link view-more">VER MÁS</a>');
     });

     $("body").on("click", ".select2-results .view-more", (e) => {
       e.preventDefault();
       $('#search-sucursales select').select2('close');
       this.concessionary = '';
       if (this.$router.history.current.name !== 'concesionarios' )
        this.$router.push({ name: 'concesionarios' })
     });

     this.$root.$on('updateSelectZone', () => {
       this.concessionary = '';
     });

     var shrinkHeader = 200;
     $(window).scroll(function() {
       var scroll = getCurrentScroll();
       if ( scroll >= shrinkHeader ) {
         $('header').addClass('shrink');
       }
       else {
         $('header').removeClass('shrink');
       }
     });
    function getCurrentScroll() {
      return window.pageYOffset || document.documentElement.scrollTop;
    };

  },
  computed: {
    getConcessionaires() {
      return this.$store.getters.getConcessionairesFormGetters;
    }
  },
  methods: {
    onSelectConcessionary(){
      var self = this;
      if (this.concessionary){
        if (this.$router.currentRoute.name != 'concesionarios')
          this.$router.push({ name: 'concesionarios' });
          this.$store.commit('setConcessionary', this.concessionary)
          this.$root.$emit('updateConcessionary');
        }
      }
    },
    updated(){
      $('#search-sucursales select').trigger('change.select2');
    },
  }
</script>
