<template>
  <main role="main">
    <section class="bg-nosotros-intro main-padding-top">
      <div class="container py-5 h-100 d-flex align-items-center">
        <div class="row pb-5">
          <div class="col-12">
            <div class="titulo">
				<img :src="app_url+'/images/MOBILE_DESDE_1953.png'" class="img-fluid d-block d-md-none "/>
				<img :src="app_url+'/images/DESDE-1953.png'" class="img-fluid d-md-block d-none"/>
			</div>
          </div>
      	</div>
      </div>
    </section>

    <section class="bg-nosotros-scroll">
    <div class="container position-relative">
        <div class="content-scroll">

            <div class="content-anio">

                  <div class="anio-info siglo-1 clearfix">
				  	  <h1 id="mil" class="siglo">19</h1>
					  <div class="anio-evento primer-anio escena-1">
					  	  <h1 class="anio-detalle">19</h1>
                          <div class="info">
                              <h3>Año 1919</h3>
                              <img :src="app_url+'/images/scroll-image/GILERA_HISTORIA_1909.jpg'" class="img-fluid"/>
                          </div>
                      </div>

					  <div class="anio-evento escena-2">
					  	  <h1 class="anio-detalle">35</h1>
                          <div class="info">
                              <h3>Año 1935</h3>
                              <p class="pr-5">Gilera adquirió los derechos del motor de cuatro cilindros Rondine, que fue la base para sus máquinas de competición durante casi cuatro décadas. Basándose en ese motor se desarrolló una gama de motores de cuatro tiempos desde los 100cc hasta los 500cc.</p>
                              <img :src="app_url+'/images/scroll-image/GILERA_HISTORIA_1935.jpg'" class="img-fluid"/>
                          </div>
                      </div>

					  <div class="anio-evento escena-4">
					  	  <h1 class="anio-detalle">51</h1>
                          <div class="info">
                              <h3>1951</h3>
                              <p>Geoff Duke, este gran piloto de origen inglés ganó sus tres primeros campeonatos mundiales en los años 1951 (350cc y 500cc) y 1952 (350cc) con Norton y sorprendió a todos firmando para una marca extranjera, el fabricante italiano Gilera. En 1953 volvió a ganar el campeonato para su nueva marca, título que repetiría los dos años siguientes. Después de retirarse de las carreras, Duke se convirtió en un hombre de negocios y creó la escudería Duke junto con Gilera para producir máquinas de competición.</p>
                              <img :src="app_url+'/images/scroll-image/GILERA_HISTORIA_1951.jpg'" class="img-fluid"/>
                          </div>
                      </div>

					  <div class="anio-evento escena-5">
                          <h1 class="anio-detalle">53</h1>
						  <div class="info">
                              <h3>1953</h3>
                              <p>Gilera, líder en la fabricación de Motocicletas en Italia y mundialmente famosa, decide en la década del cincuenta instalar una fábrica en Argentina. Inicialmente en el año 1953 se limitaba a ensamblar motos de procedencia Italiana.</p>
                              <img :src="app_url+'/images/scroll-image/GILERA_HISTORIA_1953.jpg'" class="img-fluid"/>
                          </div>
                      </div>

					  <div class="anio-evento escena-6">
                          <h1 class="anio-detalle">55</h1>
						  <div class="info">
                              <h3>1955</h3>
                              <p>En el año 1955 adquiere los primeros equipos productivos y en 1957 se constituye la firma GILERA ARGENTINA y se inicia la fabricación de las primeras piezas bajo licencia de Gilera de Italia siguiendo sus procesos de fabricación y sus normas de calidad.</p>
                              <img :src="app_url+'/images/scroll-image/GILERA_HISTORIA_1955.jpg'" class="img-fluid"/>
                          </div>
                      </div>

					  <div class="anio-evento escena-7">
                          <h1 class="anio-detalle">62</h1>
						  <div class="info">
                              <h3>1962</h3>
                              <p>En 1962 se construyen las actuales instalaciones y se equipa la planta con la más alta tecnología de la época, convirtiéndose en una planta modelo. Durante el año siguiente, alcanzó su total capacidad productiva contando en ese entonces con 600 operarios. Se partía de materias primas básicas para llegar a la fabricación completa de las motos en un proceso que abarcaba desde la fundición inicial de los metales hasta el armado final de cada una de sus piezas. En aquel entonces, Gilera producía 4 modelos diferentes de motos, como así también autopartes de la firma Citroën, ya que tenía una gran capacidad de producción. La planta industrial está localizada a 40 Km de Capital Federal en la ciudad de Carlos Spegazzini, partido de Ezeiza, provincia de Buenos Aires. Cuenta con una superficie total de 145.000 m2, de los cuales 14.000 son cubiertos.</p>
							  <img :src="app_url+'/images/scroll-image/GILERA_HISTORIA_1962.jpg'" class="img-fluid"/>
                          </div>
                      </div>

					  <div class="anio-evento escena-9">
                          <h1 class="anio-detalle">78</h1>
						  <div class="info">
                              <h3>1978</h3>
                              <p>Luego de diferentes circunstancias, la empresa llega a manos del estado y en el año 1978 se decide licitarla. Durante el transcurso de ese año, la planta fue adjudicada a Libertador Motors S.A., que compró la fábrica completa, incluyendo toda la maquinaria, la tecnología, la marca y el personal que llevaba varios años trabajando con la antigua compañía. Durante el primer año, la producción estuvo dedicada a la fabricación de una moto de 250cc. Con la apertura de la importación, se decide comprar tecnología de Italia y a la vez importar motos de ese país. La empresa se abastece de los equipos necesarios para la producción de un motor de 250cc marca Hiro, líder en Italia.</p>
                              <img :src="app_url+'/images/scroll-image/GILERA_HISTORIA_1978.jpg'" class="img-fluid"/>
                          </div>
                      </div>

					  <div class="anio-evento escena-10">
						  <h1 class="anio-detalle">83</h1>
						  <div class="info">
                              <h3>1983</h3>
                              <p>En la década de 1980, empieza a producir en el país ciclomotores de 50cc, siendo un producto de alta rotación en ventas. Se fabrica íntegramente en la planta de Spegazzini, tanto el motor como el chasis y componentes varios</p>
                              <img :src="app_url+'/images/scroll-image/GILERA_HISTORIA_1980.jpg'" class="img-fluid"/>
                          </div>
                      </div>

					  <div class="anio-evento escena-11">
                          <h1 class="anio-detalle">89</h1>
						  <div class="info">
                              <h3>1989</h3>
                              <p>En 1989 Libertador Motors es nombrada Distribuidora Oficial de las motos Japonesas marca SUZUKI , líder mundial en la fabricación de motocicletas. Durante esa época Gilera Argentina se dedica al ensamble de la línea de scooters de la marca y a la integración con partes nacionales de un ciclomotor</p>
                              <img :src="app_url+'/images/scroll-image/GILERA_HISTORIA_1989.jpg'" class="img-fluid"/>
                          </div>
                      </div>

					  <div class="anio-evento escena-12">
                          <h1 class="anio-detalle">94</h1>
						  <div class="info">
                              <h3>1994</h3>
                              <p>En 1994 comienza con la ampliación de todo su Line Up consiguiendo en la línea Business o Cub el modelo más patentado y de más suceso en los últimos años: la “SMASH”, que continúa actualmente en vigencia.</p>
                              <img :src="app_url+'/images/scroll-image/GILERA_HISTORIA_1994.jpg'" class="img-fluid"/>
                          </div>
                      </div>

					  <div class="anio-evento escena-13">
                          <h1 class="anio-detalle">98</h1>
						  <div class="info">
                              <h3>1998</h3>
                              <p>Hacia fines de los noventa comienza con la fabricación de un Cuatriciclo de 80cc con alta integración de componentes nacionales. Continúa ampliando la gama de éstos en las cilindradas de 125cc, 150cc, 200cc y 250cc Biciclindro, siendo hoy en día un producto de reconocida calidad por sus altas prestaciones.</p>
                              <img :src="app_url+'/images/scroll-image/GILERA_HISTORIA_1998.jpg'" class="img-fluid"/>
                          </div>
                      </div>
                  </div>


                  <div class="anio-info siglo-2 clearfix">
                      <h1 id="dosmil" class="siglo">20</h1>
					  <div class="anio-evento escena-14">
                          <h1 class="anio-detalle">05</h1>
						  <div class="info">
                              <h3>2005</h3>
							  <p>A mediados de la década se inaugura el primer centro de distribución en Catamarca y el Showroom más grande del país con 1.000 Mts2 destinados a la exhibición de toda la línea de productos.<br/>Con la apertura de este centro logístico, Gilera Argentina aumenta su capacidad de respuesta y abastecimiento a todo el interior del país atendiendo de manera más eficiente la creciente demanda del mercado.</p>
                              <img :src="app_url+'/images/scroll-image/GILERA_HISTORIA_2005.jpg'" class="img-fluid"/>
                          </div>
                      </div>

					  <div class="anio-evento escena-15">
                          <h1 class="anio-detalle">18</h1>
						  <div class="info">
                              <h3>2018</h3>
							  <p>En línea con la tendencia mundial de disminución de emisiones de CO2 Gilera da los primeros pasos en su ambicioso plan de ELECTROMOVILIDAD.<br/>De la mano con los importantes avances que se dieron en la Argentina en cuanto a políticas para la promoción de vehículos más eficientes y  amigables con el medio ambiente, la compañía presenta la EG-II. Este modelo se encuentra enmarcado dentro de una estrategia que busca brindar a los usuarios diversas alternativas para contribuir con el plan de movilidad limpia.<br/>Con la EG II, Gilera Argentina se transforma en la primera terminal en homologar una moto eléctrica en el país de acuerdo a los estándares oficiales.</p>
                              <img :src="app_url+'/images/scroll-image/GILERA_HISTORIA_2018.jpg'" class="img-fluid"/>
                          </div>
                      </div>

                      <div class="anio-evento ultimo-anio escena-16">
                          <h1 class="anio-detalle">20</h1>
						  <div class="info">
                              <h3>2020</h3>
							  <p>Luego de más de dos décadas de participación destacada en el mercado, especialmente en el segmento de las Cubs, Con la puesta en valor de su histórica planta en Carlos Spegazzini y la actualización de procesos de ingeniería, producción y control de calidad, Gilera Argentina da un paso más en su historia de evolución constante. Siempre adaptándose a las necesidades del mercado nacional, en la búsqueda de brindar soluciones de movilidad seguras, confiables y accesibles.</p>
                              <img :src="app_url+'/images/scroll-image/GILERA_HISTORIA_2020.jpg'" class="img-fluid"/>
                          </div>
                      </div>

                  </div>

              </div>

          </div>
      </div>
  </section>

  </main>
</template>
<script>

import { TweenMax } from 'gsap'
import ScrollMagic from 'scrollmagic'
import 'animation.gsap'


export default {
  props: {
    app_url: {
      type: String,
      default: ''
    },
  },
  mounted(){

		// init controller
		var controller1 = new ScrollMagic.Controller();

		var containerH = $('.content-anio').height();
		var anio1H = $('.siglo-1').height();
		var anio2H = $('.siglo-2').height();
		var escena1H = $('.escena-1').height();
		var escena2H = $('.escena-2').height();
		var escena4H = $('.escena-4').height();
		var escena5H = $('.escena-5').height();
		var escena6H = $('.escena-6').height();
		var escena7H = $('.escena-7').height();
		var escena9H = $('.escena-9').height();
		var escena10H = $('.escena-10').height();
		var escena11H = $('.escena-11').height();
		var escena12H = $('.escena-12').height();
		var escena13H = $('.escena-13').height();
		var escena14H = $('.escena-14').height();
		var escena15H = $('.escena-15').height();
		var escena16H = $('.escena-16').height();
		var siglo = $('#mil').height();
		var ultimo = $('.ultimo-anio').height();
		var siglo2 = siglo/2;

		// build scene
		//offset = start
		//duration = end
		var siglo1 = new ScrollMagic.Scene({triggerElement: ".siglo-1", duration: anio1H - 150, offset: (siglo/2)})
		.setPin('#mil',{pushFollowers: false})
		.addTo(controller1)

		var escena1 = new ScrollMagic.Scene({triggerElement: ".escena-1", duration: escena1H, offset: (siglo/2)})
		.setPin('.escena-1 h1',{pushFollowers: false})
		.addTo(controller1)

		var escena2 = new ScrollMagic.Scene({triggerElement: ".escena-2", duration: escena2H, offset: (siglo/2)})
		.setPin('.escena-2 h1',{pushFollowers: false})
		.addTo(controller1)

		var escena4 = new ScrollMagic.Scene({triggerElement: ".escena-4", duration: escena4H, offset: (siglo/2)})
		.setPin('.escena-4 h1',{pushFollowers: false})
		.addTo(controller1)

		var escena5 = new ScrollMagic.Scene({triggerElement: ".escena-5", duration: escena5H, offset: (siglo/2)})
		.setPin('.escena-5 h1',{pushFollowers: false})
		.addTo(controller1)

		var escena6 = new ScrollMagic.Scene({triggerElement: ".escena-6", duration: escena6H, offset: (siglo/2)})
		.setPin('.escena-6 h1',{pushFollowers: false})
		.addTo(controller1)

		var escena7 = new ScrollMagic.Scene({triggerElement: ".escena-7", duration: escena7H, offset: (siglo/2)})
		.setPin('.escena-7 h1',{pushFollowers: false})
		.addTo(controller1)

		var escena9 = new ScrollMagic.Scene({triggerElement: ".escena-9", duration: escena9H, offset: (siglo/2)})
		.setPin('.escena-9 h1',{pushFollowers: false})
		.addTo(controller1)

		var escena10 = new ScrollMagic.Scene({triggerElement: ".escena-10", duration: escena10H, offset: (siglo/2)})
		.setPin('.escena-10 h1',{pushFollowers: false})
		.addTo(controller1)

		var escena11 = new ScrollMagic.Scene({triggerElement: ".escena-11", duration: escena11H, offset: (siglo/2)})
		.setPin('.escena-11 h1',{pushFollowers: false})
		.addTo(controller1)

		var escena12 = new ScrollMagic.Scene({triggerElement: ".escena-12", duration: escena12H, offset: (siglo/2)})
		.setPin('.escena-12 h1',{pushFollowers: false})
		.addTo(controller1)

		var escena13 = new ScrollMagic.Scene({triggerElement: ".escena-13", duration: escena13H, offset: (siglo/2)})
		.setPin('.escena-13 h1',{pushFollowers: false})
		.addTo(controller1)

		var escena14 = new ScrollMagic.Scene({triggerElement: ".escena-14", duration: escena14H, offset: (siglo/2)})
		.setPin('.escena-14 h1',{pushFollowers: false})
		.addTo(controller1)

		var escena15 = new ScrollMagic.Scene({triggerElement: ".escena-15", duration: escena15H, offset: (siglo/2)})
		.setPin('.escena-15 h1',{pushFollowers: false})
		.addTo(controller1)

		var escena16 = new ScrollMagic.Scene({triggerElement: ".escena-16", duration: (escena16H - 246), offset: (siglo/2)})
		.setPin('.escena-16 h1',{pushFollowers: false})
		.addTo(controller1)


		var siglo2 = new ScrollMagic.Scene({triggerElement: ".siglo-2", duration: (anio2H - ultimo + 160), offset: (siglo/2)})
		.setPin('#dosmil',{pushFollowers: false})
		.addTo(controller1)


  }
}
</script>
