import {client} from './client';


export default {
    all(params) {
        return client.get('categories',params);
    },
    find(id) {
        return client.get(`categories/${id}`);
    },
};
