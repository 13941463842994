<template>
  <div class="encontra-menu">
    <div class="container">
      <ul  class="nav justify-content-center" role="tablist">
          <li class="nav-item" v-for="{ id, name, slug } in getCategories" :key="id" :id="id">
              <a class="nav-link" :class="(current == slug) ? 'active' : ''"  :href="'#'+ slug" :id="slug + '-tab'" @click="showCategory(slug)" data-toggle="tab" role="tab"
               :aria-controls="slug" :aria-selected="current == slug">{{ name }}</a>
          </li>
      </ul>

      <!-- Tab panes -->
      <div class="tab-content">
        <!-- SE REPITE tab-pane POR CATEGORIA CON SU CORRESPONDIENTE ID -->
        <div class="tab-pane fade" :class="(current == category.slug) ? 'active show' : ''"  :id="category.slug" role="tabpanel" :aria-labelledby="category.slug+'-tab'" v-for="category in getCategories" >
          <div class="encontra-content">
            <div class="row">
              <div class="col-md-3" v-for="{ id, name, slug, thumbnail } in category.models">
                <div class="encontra-modelo text-center" @click="showModel">
                  <router-link :to="'/modelo/'+slug">
                    <div class="img-box">
                      <img :src="thumbnail" class="img-thumb mx-auto img-fluid" width="286" height="198"/>
                    </div>
                    <h6>{{name}} <span> <strong>DESCUBRIL{{ (category.slug ==='atv' ) ? 'O' : 'A' }}</strong> <i><img :src="app_url+'/images/ico-down.png'" width="19" height="19"/></i></span></h6>
                  </router-link>
                </div>
              </div>
            </div>
        	</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      app_url: {
        type: String,
        default: ''
      },
      current: {
        type: String,
        default: ''
      },
    },
    name: 'categoryMenu',
    computed: {
      getCategories(){ //final output from here
        return this.$store.getters.getCategoriesFormGetters
      }
     },
     methods: {
      showCategory(slug){
        this.$emit('show-category', slug);
      },
      showModel(){
        this.$emit('show-model');
      }
    },
  }
</script>
