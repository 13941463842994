<template>
  <main role="main">
    <image-preloader id="test" :src="app_url+'/images/img-carousel-mb.jpg'" @loaded="loadedOne" @loaded-all="loadedAll" />
    <image-preloader id="test2" :src="app_url+'/images/banner/GILERA_BANNER_HOME_AC4.jpg'" @loaded="loadedOne" @loaded-all="loadedAll" />
    <image-preloader id="test3" :src="app_url+'/images/banner/GILERA_BANNER_HOME_LUBRICANTES.jpg'" @loaded="loadedOne" @loaded-all="loadedAll" />
    <image-preloader id="test4" :src="app_url+'/images/banner/GILERA_BANNER_HOME_EGII.jpg'" @loaded="loadedOne" @loaded-all="loadedAll" />

    <banners-component  :app_url="app_url" ></banners-component>

    <section id="modelos" class="encontra-tu-gilera">
	    <h1 id="model" class="text-center text-primary text-uppercase pt-5 mb-3"><em>elegi tu modelo</em></h1>
      <categories-component :app_url="app_url" v-on:show-category="showCategory" v-on:show-model="showModel" :current="current_category"></categories-component>
      <model-component :key="model" :model_slug="model" :app_url="app_url" v-on:load-model="onLoadModel"></model-component>
    </section>
    <section>
      <div class="container">
          <div class="row">
              <div class="col-md-8 offset-md-2 py-5">
                  <div class="text-center mb-md-5 mb-4"><router-link :to="{ name: 'concesionarios' }" class="btn btn-primary btn-lg text-uppercase">encontrá tu concesionario</router-link></div>
                    <div class="form-contacto">
                      <h1 class="text-center pt-4 mb-5 font-weight-light text-uppercase"><span class="text-primary">¿querés saber más?</span> Estamos para vos</h1>
                      <form-contact-component :app_url="app_url"></form-contact-component>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="nuevos-caminos">
      <div class="container">
          <div class="row pt-5 pb-3">
              <div class="col-12 text-center text-uppercase">
                  <h1 class="text-primary font-italic mb-0"><a href="https://www.instagram.com/gileramotorsarg/?hl=es-la" target="_blank" class="text-primary">@GILERAMOTORSARG</a></h1>
                    <h4 class="font-weight-light">seguinos en instagram</h4>
                </div>
            </div>
            <div class="row pt-4 pb-5">
              <div class="col-12 pb-3 text-center">
                <instagram-feed-component></instagram-feed-component>
              </div>
            </div>
        </div>
    </section>
  </main>
</template>
<script>
  export default {
    props: {
      app_url: {
        type: String,
        default: ''
      },
      is_show_model: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        model: null,
        model_load: false,
        current_category: null,
        windowWidth: window.innerWidth,
      };
    },
    watch: {
      windowWidth(newHeight, oldHeight) {
        //console.log(`it changed to ${newHeight} from ${oldHeight}`);
      }
    },
    created() {
      this.showModel()
    },
    updated(){
      //console.log('updated',this)
      this.showModel()
    },
    beforeMount() {
      this.$Progress.start();
    },
    mounted() {
      this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
      })
      if ( this.windowWidth >= 768  ){
        this.current_category = 'cub'
      }
      this.showModel()
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.onResize);
    },
    methods: {
      loadedOne ( e ) {
        //console.log(e)  // ImagePreloader id, loaded src, src index, loaded count, src list length, progress
        //console.log(e.progress)
      } ,
      loadedAll ( e ) {
         //console.log( e ) //  ID de ImagePreloader o boolean
      },
      onResize() {
         this.windowWidth = window.innerWidth
      },
      showModel() {

        if (this.is_show_model){

          if (this.model_load){
            $("#"+this.$route.params.slug).fadeIn(()=>{
              //this.$scrollTo("#"+this.model)
            })
          }

          this.model = this.$route.params.slug;
        }
        else{
          this.model = null;
        }
      },
      showCategory(slug){
        if (this.is_show_model){
          $("#"+this.$route.params.slug).fadeOut()
        }
      },
      onLoadModel(isLoad, current_category){
        //console.log('onLoadModel',this.model, this.is_show_model, isLoad, current_category)
        this.model_load = isLoad;
        if (this.is_show_model && isLoad) {
          if ( this.windowWidth >= 768  ){
            this.current_category = current_category
          }
          else{
            this.current_category = null
          }
            setTimeout(()=>{
              //console.log('model',this.model)
              this.$scrollTo("#"+this.model)
            },300);

        }
      },
    },
    beforeRouteEnter (to, from, next) {
      //console.log('beforeRouteEnter', to, from, next);
      /*
        if (localStorage.getItem('jwt')) {
            return next('board');
        }
      */
        next();
    }

  }
</script>
