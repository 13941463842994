<template>
  <main>
  <div class="row">
      <div v-for="feed in getInstagramFeeds" :key="'-' + feed.id" class="col-md-4 p-0">
        <div v-if="feed.media_type == 'VIDEO'" >
          <video controls :poster="feed.thumbnail_url" class="w-100 d-block">
            <source :src="feed.media_url" type="video/mp4">
            <img :src="feed.thumbnail_url" :alt="feed.caption"  class="img-fluid mx-auto"/>
          </video>

        </div>
        <div v-else>
          <img :src="feed.media_url" :alt="feed.caption"  class="img-fluid mx-auto"/>
        </div>
      </div>

    </div>

  </main>
</template>

<script>
export default {
  name: 'footerContent',
  data(){
    return {
      category_active : "",
      app_url: "",
    }
  },
  created() {
    this.app_url = this.$router.options.base;
  },
  computed: {
     getInstagramFeeds(){ //final output from here
       let size = 6, items = [];
       if ( this.$store.getters.getInstagramFeeds.data ) {
         if  ( this.$store.getters.getInstagramFeeds.data.length < size)
          size = this.$store.getters.getInstagramFeeds.data.length

        items = this.$store.getters.getInstagramFeeds.data.slice(0, size)
       }
      return items
     }
   },
   methods : {

  },
}
</script>
