/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('./vendor/select2/js/select2.min.js');
require('./vendor/slick/slick.min.js');

window.Vue = require('vue');

import VueRouter from 'vue-router'
import Vuex from 'vuex'
import VueScrollTo from 'vue-scrollto'
import VueScrollmagic from 'vue-scrollmagic'
import storeData from "./store/index"
import VueProgressBar from 'vue-progressbar'
import imagePreloader from 'vue-image-preloader'
import VueMobileDetection from 'vue-mobile-detection'
import Vue2TouchEvents from 'vue2-touch-events'

Vue.use(Vue2TouchEvents)
Vue.use(VueMobileDetection)
Vue.use(imagePreloader)
Vue.use(VueProgressBar, {
  color: '#FE7B76',
  failedColor: 'red',
  height: '15px'
})

Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(VueScrollmagic)
Vue.use(VueScrollTo, {
     container: "body",
     duration: 800,
     easing: "ease",
     offset: 0,
     force: true,
     cancelable: true,
     onStart: false,
     onDone: false,
     onCancel: false,
     x: false,
     y: true
 })

const store = new Vuex.Store(
   storeData
)

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

Vue.component('instagram-feed-component', require('./components/InstagramFeedComponent.vue').default);

Vue.component('header-component', require('./components/HeaderComponent.vue').default);
Vue.component('footer-component', require('./components/FooterComponent.vue').default);
Vue.component('categories-component', require('./components/CategoriesComponent.vue').default);
Vue.component('model-component', require('./components/ModelComponent.vue').default);
Vue.component('form-contact-component', require('./components/FormContacComponent.vue').default);
Vue.component('banners-component', require('./components/BannersComponent.vue').default);

import App   from './views/App';
import Home  from './views/Home';
import Posventa from './views/Posventa';
import Concesionarias from './views/Concesionarias';
import Nosotros from './views/Nosotros';
import NotFound from './views/NotFound';

const router = new VueRouter({
  mode: 'history',
  base: process.env.MIX_BASE_APP_URL,
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      props: { is_show_model:false, app_url:process.env.MIX_BASE_APP_URL }
    },
    {
      path: '/modelos',
      name: 'modelos',
      component: Home,
      props: { is_show_model:false, app_url:process.env.MIX_BASE_APP_URL }
    },
    {
      path: '/modelo/:slug',
      name: 'model',
      component: Home,
      props: { is_show_model:true, app_url:process.env.MIX_BASE_APP_URL }
    },
    {
      path: '/posventa',
      name: 'posventa',
      component: Posventa,
      props: {app_url:process.env.MIX_BASE_APP_URL }
    },
    {
      path: '/contacto',
      name: 'contacto',
      component: Posventa,
      props: {app_url:process.env.MIX_BASE_APP_URL }
    },
    {
      path: '/garantia',
      name: 'garantia',
      component: Posventa,
      props: {app_url:process.env.MIX_BASE_APP_URL }
    },
    {
      path: '/repuestos',
      name: 'repuestos',
      component: Posventa,
      props: {app_url:process.env.MIX_BASE_APP_URL }
    },
    {
      path: '/nosotros',
      name: 'nosotros',
      component: Nosotros,
      props: {app_url:process.env.MIX_BASE_APP_URL }
    },
    {
      path: '/concesionarios',
      name: 'concesionarios',
      component: Concesionarias,
      props: {app_url:process.env.MIX_BASE_APP_URL }
    },

    { path: '/404', name: '404', component: NotFound,   props: { app_url:process.env.MIX_BASE_APP_URL } },
    { path: '*', redirect: '/404' },
  ],
  scrollBehavior: (to, from, savedPosition) => new Promise( resolve => {

    const position = savedPosition || {};
    if (!savedPosition) {
      if (to.name === 'contacto' || to.name === 'repuestos' || to.name === 'model' || to.name === 'modelos' ) {
        position.selector = "#"+to.name ;
      }
      else{
        if (to.hash) {
          position.selector = to.hash;
        }
        else{
          position.x = 0;
          position.y = 0;
        }

      }
    }
    router.app.$root.$once('triggerScroll', () => {
      if (to.name === 'modelos'){
        setTimeout(()=>{
          router.app.$nextTick(() => resolve(position));
        },300);
      }
      else{
        router.app.$nextTick(() => resolve(position));
      }
    });
  })

  /*
  {


    if (to.name === 'contacto' || to.name === 'repuestos' || to.name === 'model' ) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ selector: "#"+to.name })
        }, 500)
      })
    }
    else if (savedPosition) {
      return savedPosition;
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ x: 0, y: 0 })
        }, 500)
      })
    }
  }*/
});

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
  el: '#app',
  store, //vuex
  components: { App },
  router,
});
