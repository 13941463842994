<template>
    <div>
      <header-component></header-component>
      <transition name="custom-classes-transition" enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in" :duration="300" @after-leave="$root.$emit('triggerScroll')">
        <router-view :key="$route.fullPath"></router-view>
      </transition>
      <footer-component></footer-component>
      <vue-progress-bar></vue-progress-bar>
    </div>
</template>
<script>
  export default {
    mounted() {
      this.$store.dispatch("allCategoryFromDatabase")
      this.$store.dispatch("allConcessionaryFromDatabase")
      this.$store.dispatch("allInstagramFeedsFromDatabase")
      this.$store.dispatch("allBannersFromDatabase")
      console.log(this.$route.name, this);
      if (this.$route.name === 'contacto' || this.$route.name === 'repuestos' || this.$route.name === 'model'  ) {
        this.$nextTick(() => {
          setTimeout(()=>{
            let id = this.$route.name;

            if ( this.$route.name === 'model' )
              id = this.$route.params.slug

            console.log(this.$route.name, id);
            this.$scrollTo("#"+id)
          },300);
        })
      }
      this.$Progress.finish()
    },
    created () {
      //  [App.vue specific] When App.vue is first loaded start the progress bar
      this.$Progress.start()
      //  hook the progress bar to start before we move router-view
      this.$router.beforeEach((to, from, next) => {
      //  does the page we want to go to have a meta.progress object
        if (to.meta.progress !== undefined) {
          let meta = to.meta.progress
          // parse meta tags
          this.$Progress.parseMeta(meta)
        }
        //  start the progress bar
        this.$Progress.start()
        //  continue to next page
        next()
      })
      //  hook the progress bar to finish after we've finished moving router-view
      this.$router.afterEach((to, from) => {
        //  finish the progress bar
        this.$Progress.finish()
      })
    }
  }
</script>
