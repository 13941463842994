import {client} from './client';

export default {
  all(params) {
    //console.log(params)
    return client.get('concessionaires', {params});
  },
  find(id) {
    return client.get(`concessionaires/${id}`);
  },
};
