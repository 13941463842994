var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"encontra-menu"},[_c('div',{staticClass:"container"},[_c('ul',{staticClass:"nav justify-content-center",attrs:{"role":"tablist"}},_vm._l((_vm.getCategories),function(ref){
var id = ref.id;
var name = ref.name;
var slug = ref.slug;
return _c('li',{key:id,staticClass:"nav-item",attrs:{"id":id}},[_c('a',{staticClass:"nav-link",class:(_vm.current == slug) ? 'active' : '',attrs:{"href":'#'+ slug,"id":slug + '-tab',"data-toggle":"tab","role":"tab","aria-controls":slug,"aria-selected":_vm.current == slug},on:{"click":function($event){return _vm.showCategory(slug)}}},[_vm._v(_vm._s(name))])])}),0),_vm._v(" "),_c('div',{staticClass:"tab-content"},_vm._l((_vm.getCategories),function(category){return _c('div',{staticClass:"tab-pane fade",class:(_vm.current == category.slug) ? 'active show' : '',attrs:{"id":category.slug,"role":"tabpanel","aria-labelledby":category.slug+'-tab'}},[_c('div',{staticClass:"encontra-content"},[_c('div',{staticClass:"row"},_vm._l((category.models),function(ref){
var id = ref.id;
var name = ref.name;
var slug = ref.slug;
var thumbnail = ref.thumbnail;
return _c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"encontra-modelo text-center",on:{"click":_vm.showModel}},[_c('router-link',{attrs:{"to":'/modelo/'+slug}},[_c('div',{staticClass:"img-box"},[_c('img',{staticClass:"img-thumb mx-auto img-fluid",attrs:{"src":thumbnail,"width":"286","height":"198"}})]),_vm._v(" "),_c('h6',[_vm._v(_vm._s(name)+" "),_c('span',[_c('strong',[_vm._v("DESCUBRIL"+_vm._s((category.slug ==='atv' ) ? 'O' : 'A'))]),_vm._v(" "),_c('i',[_c('img',{attrs:{"src":_vm.app_url+'/images/ico-down.png',"width":"19","height":"19"}})])])])])],1)])}),0)])])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }