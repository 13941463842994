<template>
  <footer>
    <div class="container">
    	<div class="row">
      	<div class="col-8 col-md-4 offset-2 offset-md-0 text-center d-block d-md-none mb-5"><img :src="app_url+'/images/gilera-blanco.png'" width="239" height="28" alt="Gilera" class="img-fluid"/></div>
		<div class="col-8 col-md-4 offset-2 offset-md-0">
          <ul class="float-md-left list-unstyled mr-5 mb-5 mb-md-2">
            <h4 class="mb-4">Gilera</h4>
            <li><router-link :to="{ name: 'nosotros' }">Historia</router-link></li>
            <li><router-link :to="{ name: 'concesionarios' }">Concesionarios</router-link></li>
          </ul>

          <ul class="float-md-left list-unstyled mb-5 mb-md-2">
            <h4 class="mb-4">Modelos</h4>
            <li v-for="{ id, name, slug, models } in getCategories" :key="id" :id="id" @click="showModels($event, slug)">
              <a :href="'#'+ slug" :id="slug">{{ name }}</a>
              <transition-group name="flip-list" tag="ul" v-if="category_active==slug" class="list-unstyled ml-4">
                <li v-for="model in models" :key="id + '-' + model.id"><router-link :to="'/modelo/'+model.slug">{{ model.name }}</router-link></li>
              </transition-group>
            </li>
          </ul>
        </div>
        <div class="col-8 col-md-4 offset-2 offset-md-0 text-center d-none d-md-block"><img :src="app_url+'/images/gilera-blanco.png'" width="239" height="28" alt="Gilera" class="img-fluid"/></div>
          <div class="col-8 col-md-4 offset-2 offset-md-0 d-flex justify-content-md-end">
            <div>
              <h4 class="mb-4">Contacto</h4>
	            <p>Marconi 805, Carlos Spegazzini<br>[B1812CDE], Buenos Aires<br><a href="mailto:info@gilera.com.ar">info@gilera.com.ar</a><br>02274.430241</p>
              <div class="social-medias">
                <a href="https://www.youtube.com/channel/UCQcoMNvLbzsu89RkcTmVB8A" target="_blank" class="icons mx-1"><img :src="app_url+'/images/icon-youtube.svg'" width="30" height="30" alt="youtube"/></a>
                <a href="https://www.facebook.com/GileraMotorsARG" target="_blank" class="icons mx-1"><img :src="app_url+'/images/icon-facebook.svg'" width="30" height="30" target="_blank" alt="facebook"/></a>
                <a href="https://www.instagram.com/gileramotorsarg/" target="_blank" class="icons mx-1"><img :src="app_url+'/images/icon-instagram.svg'" width="30" height="30" alt="instagram"/></a>
              </div>
          </div>
        </div>
      </div>
      <div class="row pt-5 pt-md-3">
    	   <div class="col-12 text-center"><p class="copyright">Copyright 1953-2020 Gilera All Rights Reserved
           <!--
           <a v-if="1==2" href="#">Privacy</a> |  <a href="#">Policy SMS</a> | <a href="#">Terms & Conditions</a> | <a href="#">Sitemap</a>
          -->
         </p>
         </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'footerContent',
  data(){
    return {
      category_active : "",
      app_url: "",
    }
  },
  created() {
    this.app_url = this.$router.options.base;
  },
  computed: {
     getCategories(){ //final output from here
       return this.$store.getters.getCategoriesFormGetters
     }
   },
   methods : {
    showModels(e,slug){
      e.preventDefault();
      if (this.category_active == slug)
        this.category_active = '';
      else
        this.category_active = slug;
    }

  },
}
</script>
